.footer{
    margin-top: 1rem;
    background: #38b6ff;
    padding-bottom: 1rem;
}
.footer .credit{
    color:black;
    text-align: center;
    padding:1rem;
    padding-top: 2rem;
    font-size: 2rem;
}

.footer .credit span{
    color:black;
}


.footer .share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .footer .share a {
    color: #fff;
    border-color: #fff;
  }
  
  .footer .share a:hover {
    color: #130f40;
  }
  
  .footer .share a::before {
    background: #fff;
  }
  
  .footer .share a i {
    padding-right: .5rem;
  }
