@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300&display=swap');

:root{
    --main-color:#FF7F50;
    --black:#555;
    --light-color:#777;
    --bg:#f9f9f9;
    --border:.1rem solid rgba(0,0,0,.1);
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
    /* width: 145%; */
    
}

*{
    font-family: 'Nunito', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    outline: none; border:none;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .3s ease-out;
}

html{
    font-size: 62.5%;
    scroll-behavior: smooth;
    scroll-padding-top: 9rem;
    overflow-x: hidden;
}


section{
    padding:2rem 9%;
}

.heading{
    text-align: center;
    padding-bottom: 3rem;
    font-size: 4rem;
    color:var(--black);
}

.heading span{
    color:var(--main-color);
    border-radius: .5rem;
    background: #fff;
    padding:0 .5rem;
}

.btn{
    margin-top: 1rem;
    display: inline-block;
    padding:.8rem 3rem;
    font-size: 1.7rem;
    cursor: pointer;
    color:#fff;
    background:var(--main-color);
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    position: relative;
    z-index: 0;
    overflow:hidden;
}

.btn::before{
    content: '';
    position: absolute;
    top:0; right:0;
    height: 100%;
    width:0%;
    background:var(--black);
    z-index: -1;
    transition: .2s ease-out;
}

.btn:hover::before{
    left:0;
    width:100%;
}





/* media queries  */
@media (max-width:991px){

    html{
        font-size: 55%;
    }

    .header{
        padding:2rem;
    }

    section{
        padding:2rem;
    }

}

@media (max-width:768px){

    #menu-btn{
        display: initial;
    }

    .header .navbar{
        position: absolute;
        top:115%; right: 2rem;
        background:#fff;
        box-shadow: var(--box-shadow);
        border:var(--border);
        border-radius: .5rem;
        width: 30rem;
        transform: scale(0);
        transform-origin: top right;
        opacity: 0;
    }

    .header .navbar.active{        
        transform: scale(1);
        opacity: 1;
    }

    .header .navbar a{
        font-size: 2rem;
        display: block;
        padding:1rem;
        margin: 1rem;
        border-radius: .5rem;
    }

    .header .navbar a:hover{
        background: var(--bg);
    }

    .home .content{
        text-align: center;
    }

}

@media (max-width:450px){

    html{
        font-size: 50%;
    }

}


@media (max-width:550px){

    html{
        width: 145%;
    }

}