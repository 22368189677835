
.about .row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:1.5rem;
}

.about .row .image{
    flex:1 1 45rem;
    padding: 2rem;
}

.about .row .image img{
    width: 100%;
}

.about .row .content{
    flex:1 1 45rem;
}

.about .row .content .title{
    font-size:3rem;
    color:var(--black);
}

.about .row .content p{
    font-size:1.5rem;
    color:var(--light-color);
    line-height: 1.8;
    padding: 1rem 0;
}

.about .row .content .icons-container{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    padding-top: 2rem;
}

.about .row .content .icons-container .icons{
    flex:1 1 15rem;
    border-radius: .5rem;
    border:var(--border);
    padding:1.5rem;
    text-align: center;
}

.about .row .content .icons-container .icons i{
    border-radius: 50%;
    background: var(--bg);
    color:var(--main-color);
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    font-size: 2rem;
}

.about .row .content .icons-container .icons h3{
    color:var(--light-color);
    font-size: 1.6rem;
    padding-top: 1rem;
}