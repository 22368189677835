
.header{
    position: fixed;
    top:0; left:0; right: 0;
    z-index: 1000;
    background: #fff;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:2rem 6%;
}

.header .logo{
    font-size: 2.5rem;
    color:var(--black);
    font-weight: bolder;

    display: flex;
    justify-content: center;
    align-items: center;
}

.header .navbar a{
    font-size: 1.9rem;
    color: var(--light-color);
    margin-left: 2rem;
}

.header .navbar a:hover{
    color:var(--main-color);
}

.try{
    background-color: #FF7F50;
    padding: 1rem 3rem;
    font-size: 1.3rem;
    color: #fff;
    border-radius: .5rem;
}
.try:hover{
    padding: 1rem 3rem;
    color: #000;
    border-radius: .5rem;
}

#menu-btn{
    cursor: pointer;
    font-size: 2.5rem;
    padding:1rem 1.3rem;
    border-radius: .5rem;
    color: var(--main-color);
    background: var(--bg);
    display: none;
}

#menu-btn:hover{
    color: #fff;
    background: var(--main-color);
}
.logo img{
    padding-right: .5rem;
}